export const ADD_MESSAGE = 'ADD_MESSAGE';
export const LOAD_DISCUSSION = 'LOAD_DISCUSSION';

export const addMessage = (message, isUserAnswer) => ({
  type: ADD_MESSAGE, 
  message,
  timestamp : Date.now(),
  isUserAnswer
});

export const loadDiscussion = (name) => ({
  type: LOAD_DISCUSSION, 
  name,
});
import * as Actions from '../actions/fl';

const initialState = {
    done:false,
    play:false,
    ln:"fr",
    showWelcome: true,
    showEndgame: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.FL_DONE:
            return { 
                ...state,
                done : true 
            };
        case Actions.CLOSE_WELCOME:
            return { 
                ...state,
                showWelcome : false 
            };
        case Actions.SHOW_ENDGAME:
            return { 
                ...state,
                showEndgame : true 
            };
        case Actions.FL_TOOGLE_PLAY:
            return { 
                ...state,
                play : (action.newState)?action.newState:!state.play
            };
        default:
            return state;
    }
};
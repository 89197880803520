export const SHOW_POI = 'SHOW_POI';
export const TOOGLE_MAP = 'TOOGLE_MAP';

export const showPOI = name => ({
  type: SHOW_POI, 
  name
});

export const toogleMap = val => ({
  type: TOOGLE_MAP, 
  val
});
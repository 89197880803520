export const FL_DONE = 'FL_DONE';
export const CLOSE_WELCOME = 'CLOSE_WELCOME';
export const SHOW_ENDGAME = 'SHOW_ENDGAME';
export const FL_TOOGLE_PLAY = 'FL_TOOGLE_PLAY';

export const done = () => ({
  type: FL_DONE,
});

export const closeWelcome = () => ({
  type: CLOSE_WELCOME,
});

export const endGame = () => ({
  type: SHOW_ENDGAME,
});

export const tooglePlay = (newState = null) => ({
  type: FL_TOOGLE_PLAY,
  newState
});
export const ADD_ITEM = 'ADD_ITEM';
export const OPEN_ITEM = 'OPEN_ITEM';
export const GET_CLUE = 'GET_CLUE';

export const addItem = item => ({
  type: ADD_ITEM, 
  item
});

export const openItem = id => ({
  type: OPEN_ITEM, 
  id
});

export const getClue = (id, ln) => ({
  type: GET_CLUE, 
  id,
  ln
});
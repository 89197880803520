import * as Actions from '../actions/system';

const initialState = {
    activeTab:1,
    newOnMap:true,
    newOnFiles:false,
    newOnChat:false,
    item: null,
    annecdote: null,
    startTime:0,
    endTime:0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.ACTIVE_TAB:
            return { 
                ...state,
                activeTab : action.num 
            };
        case Actions.NEW_ON_MAP:
            return { 
                ...state,
                newOnMap : action.val 
            };
        case Actions.NEW_ON_FILES:
            return { 
                ...state,
                newOnFiles : action.val 
            };
        case Actions.NEW_ON_CHAT:
            return { 
                ...state,
                newOnChat : action.val 
            };
        case Actions.OPEN_ITEM:
            return { 
                ...state,
                item : action.name 
            };
        case Actions.CLOSE_ITEM:
            return { 
                ...state,
                item : null
            };
        case Actions.OPEN_ANNECDOTE:
            return { 
                ...state,
                annecdote : action.name 
            };
        case Actions.CLOSE_ANNECDOTE:
            return { 
                ...state,
                annecdote : null
            };
        case Actions.START:
            return { 
                ...state,
                startTime : Date.now()
            };
        case Actions.END:
            return { 
                ...state,
                endTime : Date.now()
            };
        default:
            return state;
    }
};
import * as Actions from '../actions/map';

const initialState = {
    "or" : false,           // red
    "bellecourt" : false,   // red
    "canut" : false,        // blue
    "basilique" : false,    // blue,
    "active" : true // map in general
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.SHOW_POI:
            let newState = {...state};
            newState[action.name] = true;
            return newState;
        case Actions.TOOGLE_MAP:
            let newStateMap = {...state};
            newStateMap.active = action.val;
            return newStateMap;
        default:
            return state;
    }
};
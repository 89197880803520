export const ACTIVE_TAB = 'ACTIVE_TAB';
export const NEW_ON_MAP = 'NEW_ON_MAP';
export const NEW_ON_CHAT = 'NEW_ON_CHAT';
export const NEW_ON_FILES = 'NEW_ON_FILES';
export const OPEN_ITEM = 'OPEN_ITEM';
export const CLOSE_ITEM = 'CLOSE_ITEM';
export const OPEN_ANNECDOTE = 'OPEN_ANNECDOTE';
export const CLOSE_ANNECDOTE = 'CLOSE_ANNECDOTE';
export const START = 'START';
export const END = 'END';

export const activeTab = (num) => ({
  type: ACTIVE_TAB,
  num
});

export const newOnMap = (val) => ({
  type: NEW_ON_MAP,
  val
});

export const newOnFiles = (val) => ({
  type: NEW_ON_FILES,
  val
});

export const newOnChat = (val) => ({
  type: NEW_ON_CHAT,
  val
});

export const openItem = (name) => ({
  type: OPEN_ITEM,
  name
});

export const closeItem = (name) => ({
  type: CLOSE_ITEM,
  name
});

export const openAnnecdote = (name) => ({
  type: OPEN_ANNECDOTE,
  name
});

export const closeAnnecdote = () => ({
  type: CLOSE_ANNECDOTE,
});

export const start = () => ({
  type: START,
});

export const end = () => ({
  type: END,
});
import * as Actions from '../actions/inventory';

const initialState = {
    items : [
    ]
}

const item_bd = {
  "parc.jpg" : {
    content:{
      "fr": {
        img:"parc.jpg",
        clues: [
          "Il faut utiliser les numéro et la légende",
          "Attention, où est le nord ?",
          "1857",
        ],
      },
      "en": {
        img:"parc_en.jpg",
        clues: [
          "You need to use the number of the legend",
          "Pay attention to the north...",
          "1857"
        ],
      }
    },
    isNew:true,
    cluesUsed : 0,
  },
  "postcard.jpg" : {
    content:{
      "fr": {
        img:"postcard.jpg",
        clues: [
          "La citation doit indiquer quelque chose sur ce côté",
          "Louis XIV était appelé Dieudonné",
          "Il faut trouver l'année de sa mort",
          "1715",
        ],
      },
      "en": {
        img:"postcard.jpg",
        clues: [
          "The quote indicate something on this side",
          "Louis XIV was nicknamed Dieudonné",
          "We need to find out his death's year",
          "1715"
        ],
      }
    },
    isNew:true,
    cluesUsed : 0
  },
  "bas_inside.jpg" : {
    content:{
      "fr": {
        img:"bas_inside.jpg",
        clues: [
          "On cherche des textes",
          "En haut, j'en vois plusieurs !",
          "Consilii et Scientia"
        ],
      },
      "en": {
        img:"bas_inside.jpg",
        clues: [
          "We're looking for texts",
          "At the top of the picture",
          "Consilii et Scientia"
        ],
      }
    },
    
    isNew:true,
    cluesUsed : 0
  },
  "bas_outside.jpg" : {
    content:{
      "fr": {
        img:"bas_outside.jpg",
        clues: [
          "On cherche des textes",
          "En bas, j'en vois plusieurs !",
          "In n Patris et Et Spiritus"
        ],
      },
      "en": {
        img:"bas_outside.jpg",
        clues: [
          "We're looking for texts",
          "At the bottom of the picture",
          "In n Patris et Et Spiritus"
        ],
      }
    },
    isNew:true,
    cluesUsed : 0
  },
  "doc_canuts.jpg" : {
    content:{
      "fr": {
        img:"doc_canuts.jpg",
        clues: [
          "Une date est écrite d'une façon différente",
          "1831"
        ],
      },
      "en": {
        img:"doc_canuts_en.jpg",
        clues: [
          "A date is written differently",
          "1831"
        ],
      }
    },
    isNew:true,
    cluesUsed : 0
  },
  "manuscrit.jpg" : {
    content:{
      "fr": {
        img:"manuscrit.jpg",
        clues: [
          "D'abord l'extérieur de la basilique, puis l'intérieur",
          "Il faut trouver ses mots pout les remettre dans l'ordre",
          "1791"
        ],
      },
      "en": {
        img:"manuscrit_en.jpg",
        clues: [
          "First the outside then the inside",
          "Find this word to put them in order",
          "1791"
        ],
      }
    },
    isNew:true,
    cluesUsed : 0
  },
  "note.jpg" : {
    content:{
      "fr": {
        img:"note.jpg",
        clues: [
          "Les noms des lieux sont indiqués sur la carte",
          "Chaque lieu donne une couleur",
          "Si on trace un trait entre eux, on tombe sur un lieu en particulier",
          "L'Hôtel de Ville"
        ],
      },
      "en": {
        img:"note_en.jpg",
        clues: [
          "The place's names are indicated on the map",
          "Each location unlocked a color",
          "If you make a line between the location, you find a particular place",
          "City Hall - ''Hôtel de ville'' in french"
        ],
      }
    },
    isNew:true,
    cluesUsed : 0
  },
  "paper.png" : {
    content:{
      "fr": {
        img:"paper.png",
        clues: [
          "Il faut compléter le mot pour trouver les lettres",
          "Sur la photo, on distingue B A R T H",
          "1892"
        ],
      },
      "en": {
        img:"paper.png",
        clues: [
          "You should complete to word to find the letters",
          "On the picture, we can see B A R T H",
          "1892"
        ],
      }
    },
    isNew:true,
    cluesUsed : 0
  },
  "photo.jpg" : {
    content:{
      "fr": {
        img:"photo.jpg",
        clues: [
          "Il y a une partie du nom de l'auteur présent quelque part...",
          "On distingue B A R T H",
        ],
      },
      "en": {
        img:"photo.jpg",
        clues: [
          "There is the autor's name somewhere",
          "We see a part of it: ...BARTH..."
        ],
      }
    },
    isNew:true,
    cluesUsed : 0
  },
  "postcard_back.jpg" : {
    content:{
      "fr": {
        img:"postcard_back.jpg",
        clues: [
          "Cette citation de Dieudonné fait référence à sa mort",
          "Louis XIV était appelé Dieudonné",
        ],
      },
      "en": {
        img:"postcard_back_en.jpg",
        clues: [
          "This quote of Dieudonné talk about his death",
          "Louis XIV was nicknamed Dieudonné"
        ],
      }
    },
    isNew:true,
    cluesUsed : 0
  },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.ADD_ITEM:
            return { 
                ...state, 
                items : [
                    ...state.items,
                    {... item_bd[action.item]}
                ] 
            };
        case Actions.OPEN_ITEM:
            const id = action.id;
            let newitems = [...state.items]
            newitems[action.id] = {
                ...state.items[id],
                isNew: false
            }

            return {
                ...state, 
                items:newitems
            };
           return state;
        case Actions.GET_CLUE:
          const currentClues = state.items[action.id].cluesUsed;
          const nbClues = state.items[action.id].content[action.ln].clues.length;

          if(currentClues >= nbClues)
            return state;
          else {
            const nextClue = currentClues+1;
            let newitems = [...state.items]
            newitems[action.id] = {
                ...state.items[action.id],
                cluesUsed: nextClue
            }
            return {
              ...state, 
              items:newitems
            };
          }
        default:
            return state;
    }
};
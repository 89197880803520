import * as Actions from '../actions/lucas';

const initialState = {
    messages : [],
    discussion: "",
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.ADD_MESSAGE:
            return { 
                ...state, 
                messages : [
                    ...state.messages,
                    {value:action.message, timestamp:action.timestamp, isUserAnswer:action.isUserAnswer}
                ] 
            };
        case Actions.LOAD_DISCUSSION:
            return { 
                ...state, 
                discussion:action.name 
            };
        default:
            return state;
    }
};